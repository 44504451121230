import React, { memo } from 'react';
import Seo from '../../components/Seo';
import styled from 'styled-components';
import Layout from '../../components/Layout';
import BreadCrumb from '../../components/BreadCrumb/BreadCrumb';
import PostSidebar from '../../components/PostSidebar/PostSidebar';
// sections for this page
import ScrollButton from '../../components/ScrollButton';

import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import { convertToBgImage } from 'gbimage-bridge';
import BackgroundImage from 'gatsby-background-image';

const Wrapper = styled.div`
  max-width: 1180px;
  margin: 0 auto;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: block;

  @media (min-width: 992px) {
    display: flex;
  }
`;
const PostContent = styled.article`
  margin-top: 20px;
`;

const PostTemplate = memo(({ data }) => {
  const featuredimage = getImage(data.post.featuredImage.node.gatsbyImage);
  const bgImage = convertToBgImage(featuredimage);

  return (
    <BackgroundImage
      // Spread bgImage into BackgroundImage:
      {...bgImage}
      preserveStackingContext
      className="masthead3"
    >
      <Layout fullMenu>
        <Seo title={data.post.title} description={data.post.excerpt} />
        <ScrollButton />
        <article id="blogpost">
          <header>
            <h2 dangerouslySetInnerHTML={{ __html: data.post.title }} />
            <p>Surge Knowledge Bank</p>
          </header>

          <section className="wrapper style2">
            <Wrapper>
              <ContentWrapper>
                <PostSidebar categories={data.allWpCategory.nodes} />
                <PostContent>
                  <BreadCrumb
                    parent={{
                      uri: '/category/knowledge-bank/',
                      title: 'Knowledge',
                    }}
                  />

                  <div
                    dangerouslySetInnerHTML={{ __html: data.post.content }}
                  />
                </PostContent>
              </ContentWrapper>
            </Wrapper>
          </section>
        </article>
      </Layout>
    </BackgroundImage>
  );
});

export default PostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    post: wpPost(id: { eq: $id }) {
      title
      content
      excerpt
      featuredImage {
        node {
          gatsbyImage(
            width: 1000
            placeholder: BLURRED
            quality: 90
            formats: AUTO
          )
        }
      }
      author {
        node {
          name
        }
      }
      date(formatString: "DD MM YYYY")
      categories {
        nodes {
          id
          name
          uri
          slug
        }
      }
    }
    allWpCategory {
      nodes {
        name
        id
        uri
        slug
      }
    }
  }
`;
