import React, { memo } from 'react';
import { Link } from 'gatsby';
import { Wrapper, Menu } from './PostSidebar.styles';

const PostSidebar = memo(({ categories }) => {
  const sortedCategories = [...categories].sort((x, y) => {
    if (x.slug === 'knowledge-bank') return -1;
    if (y.slug === 'knowledge-bank') return 1;
    return 0;
  });

  return (
    <Wrapper>
      <Menu>
        <li className="sidebar-section">
          <span>Categories</span>
        </li>
        {sortedCategories.map((cat) =>
          cat.slug !== 'all-posts' ? (
            <li key={cat.id}>
              <Link to={`${cat.uri}`}>
                <span dangerouslySetInnerHTML={{ __html: cat.name }} />
              </Link>
            </li>
          ) : null
        )}
      </Menu>
    </Wrapper>
  );
});

export default PostSidebar;
